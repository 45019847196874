.form-control-feedback {
  ul {
    padding: 0.3rem;
    padding-left: 0.9rem !important;
    li {
      color: red;
    }
  }
}

.ck-editor__editable_inline {
  height: 600px;
  min-height: 600px;
}

.form-switch-custom .form-check-input::before {
  top: -3px;
  left: 2px;
}

.form-switch-custom .form-check-input:checked::before {
  top: -3px;
  right: 2px;
}